import React from 'react'
import PropTypes from 'prop-types'
import { AddCircle, RemoveCircle } from 'emotion-icons/material'

import Typography from '../Typography'

const AccordionItem = ({
  item: { title, content },
  index,
  isOpen,
  handleOpen,
}) => {
  function handleClick(event) {
    if (
      event.type === 'click' ||
      (event.type === 'keydown' && event.key === 'Enter')
    ) {
      event.preventDefault()
      handleOpen(index)
    }
  }

  return (
    <details className="accordion-item" open={isOpen}>
      <summary
        className="accordion-item__header"
        role="button"
        tabIndex={0}
        onClick={handleClick}
        onKeyDown={handleClick}
      >
        {isOpen ? <RemoveCircle /> : <AddCircle />}
        <Typography variant="feature">{title}</Typography>
      </summary>
      <div className="accordion-item__content">{content}</div>
    </details>
  )
}

AccordionItem.propTypes = {
  item: PropTypes.shape({
    title: PropTypes.string.isRequired,
    content: PropTypes.node.isRequired,
    open: PropTypes.bool,
  }).isRequired,
  index: PropTypes.number.isRequired,
  isOpen: PropTypes.bool.isRequired,
  handleOpen: PropTypes.func.isRequired,
}

export default AccordionItem

import React, { useState } from 'react'
import PropTypes from 'prop-types'

import AccordionItem from './AccordionItem'
import * as Styled from './Accordion.styles'

const Accordion = ({ items }) => {
  const [openItems, setOpenItems] = useState(Array(items.length).fill(false))
  const numberOpen = openItems.reduce(
    (acc, currItem) => (currItem === true ? acc + 1 : acc),
    0
  )

  function openOrCloseAll(event) {
    if (
      event.type === 'click' ||
      (event.type === 'keydown' && event.key === 'Enter')
    ) {
      // check if open all or close all button called this function
      const dataOpen = event.target.dataset.open === 'true'
      const newOpenItems = [...openItems].fill(dataOpen)
      setOpenItems(newOpenItems)
    }
  }

  function handleOpen(index) {
    const newOpenItems = [...openItems]
    newOpenItems[index] = !newOpenItems[index]
    setOpenItems(newOpenItems)
  }

  return (
    <Styled.Accordion>
      {items.length > 3 && (
        <Styled.ButtonGroup>
          <Styled.Button
            type="button"
            data-open="true"
            onClick={openOrCloseAll}
            onKeyDown={openOrCloseAll}
            disabled={numberOpen === openItems.length}
          >
            Expand All
          </Styled.Button>

          <Styled.Button
            type="button"
            data-open="false"
            onClick={openOrCloseAll}
            onKeyDown={openOrCloseAll}
            disabled={numberOpen === 0}
          >
            Collapse All
          </Styled.Button>
        </Styled.ButtonGroup>
      )}

      {items.map((item, index) => (
        <AccordionItem
          key={item.title}
          index={index}
          item={item}
          isOpen={openItems[index]}
          handleOpen={handleOpen}
        />
      ))}
    </Styled.Accordion>
  )
}

Accordion.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
}

export default Accordion

import React from 'react'
import Typography from '../components/Typography'
import Accordion from '../components/Accordion'
import Link from '../components/Link'

// TODO: Create common CA Accordions,
// go through and add all the accordions
// get the build working again so you can visually verify everything is correct

export const geoLocations = [
  'California',
  'Delaware',
  'Illinois',
  'Indiana',
  'Maryland',
  'Massachusetts',
  'Michigan',
  'New Jersey',
  'New York',
  'Ohio',
  'Georgia',
  'Texas',
  'Alberta',
  'British Columbia',
  'Ontario',
  'Quebec',
  'Saskatchewan',
  'Manitoba',
]

const commonUSAccordions = [
  {
    title: 'Connection and Service',
    content: (
      <Accordion
        items={[
          {
            title: 'What services does Just Energy offer?',
            content: (
              <Typography>
                Established in 1997, Just Energy (NYSE:JE, TSX:JE) is a leading
                retail consumer company specializing in electricity and natural
                gas commodities, energy efficiency solutions, and renewable
                energy options. Just Energy serves residential and commercial
                customers in Canada, the U.S., Ireland, the U.K., Germany, and
                Japan. We provide homes and businesses with a broad range of
                energy solutions that deliver greater comfort, convenience, and
                control. In some markets, our commodity offerings vary. See the
                list below to find out if services may be limited to either
                electricity or natural gas. Electricity Only Delaware Texas
                Massachusetts Gas Only California Georgia Indiana Michigan
              </Typography>
            ),
          },
          {
            title:
              'How long after signing up will Just Energy take effect as my supplier?',
            content: (
              <Typography>
                Depending on the region you’re located in, it may take up to
                several weeks for your enrollment to begin after you first sign
                your contract with Just Energy. If it has been longer than the
                approximate time below for your state, and you still do not see
                Just Energy listed on your utility bill, please contact us.
                State Approximate time to enrollment from signing Delaware 4
                days California 1-2 billing cycles Georgia 7-10 days, or next
                billing cycle Illinois 5 days Indiana 5 days Maryland 7 days
                Massachusetts 8 days Michigan 3 days New Jersey 15 - 120 days
                New York 8 days Ohio 5 days Pennsylvania 8 days Texas 2 days
              </Typography>
            ),
          },
          {
            title:
              'How can I transfer my Just Energy service to a new address?',
            content: (
              <Typography>
                Service transfer depends on your location. Please contact Just
                Energy with your move request. Depending on your area, we will
                either process your move or terminate the agreement (at our sole
                discretion).
              </Typography>
            ),
          },
          {
            title: 'Can I have service at two addresses at the same time?',
            content: (
              <Typography>
                Yes, you can. However, we require a separate contract for each
                address.
              </Typography>
            ),
          },
          {
            title: 'Does transferring my service affect my contract?',
            content: (
              <Typography>
                No, the existing contract will still stay in effect. The only
                change will be the service address on record.
              </Typography>
            ),
          },
          {
            title: 'Will I have to pay a fee to transfer my service?',
            content: (
              <Typography>
                No, there is no fee required to transfer your service.
              </Typography>
            ),
          },
          {
            title: 'How can I cancel my Just Energy contract?',
            content: (
              <Typography>
                You can contact our customer service team to cancel your
                contract, but note that early termination fees may apply. Just
                Energy Customer Service:{' '}
                <a href="tel:1-877-240-3931">1-877-240-3931</a>
              </Typography>
            ),
          },
          {
            title: 'How do I change my Just Energy plan?',
            content: (
              <Typography>
                We understand that life and situations change, and with these
                adjustments, your energy needs may vary. Please call us at
                1-866-587-8674 to help you find the right energy plan for you.
              </Typography>
            ),
          },
          {
            title: 'What are some types of energy plans offered by retailers?',
            content: (
              <Typography>
                Typically, energy retailers offer price protected plans
                (allowing you to secure your commodity supply rate for a set
                period of time) and variable-rate plans (where the commodity
                supply rate would change with shifts in the market for a set
                period of time).{' '}
                <Link to="/learning-center/price-volatility">Learn more</Link>{' '}
                about the benefits and why you would be interested in one plan
                over the other.
              </Typography>
            ),
          },
          {
            title: "What is the main benefit of Just Energy's offered plan?",
            content: (
              <Typography>
                Fixed-rate plans offer customers a set rate for their commodity
                supply over a period of time. These plans provide protection
                from market price variation as your rate is fixed and not
                affected by changes in commodity prices. Please refer to our
                <Link to="/learning-center/prices-and-products">
                  plan type guide
                </Link>{' '}
                to better understand the benefits of the different types of
                plans.
              </Typography>
            ),
          },
        ]}
      />
    ),
  },
  {
    title: 'Green Energy and JustGreen',
    content: (
      <Accordion
        items={[
          {
            title: 'Does Just Energy offer green energy products?',
            content: (
              <Typography>
                Yes, Just Energy does offer green energy products in all of our
                service areas.
              </Typography>
            ),
          },
          {
            title: 'What is a green energy product?',
            content: (
              <Typography>
                For electricity, a green energy product means Just Energy
                purchases renewable energy credits to offset the equivalent of a
                percentage of your household electricity consumption. Renewable
                energy credits support electricity generated from renewable
                sources such as hydro, solar, or wind. For natural gas, a green
                energy product means that Just Energy purchases carbon offsets
                to offset the equivalent of a percentage of your household
                natural gas consumption, which helps reduce your environmental
                impact.
              </Typography>
            ),
          },
          {
            title: 'Why should I buy a green energy product?',
            content: (
              <Typography>
                Buying a green energy product like JustGreen is your opportunity
                to take action on the growing global and local urgency
                associated with climate change, while simultaneously increasing
                consumer demand for sustainable and renewable green energy
                solutions.
              </Typography>
            ),
          },
          {
            title: 'How Do I Sign Up For JustGreen™?',
            content: (
              <Typography>
                When you sign up for an energy plan with Just Energy, you can
                choose to offset some or all of your energy consumption for an
                added cost, when signing the contract. You can also contact us
                to sign up over the phone as an existing customer.
              </Typography>
            ),
          },
          {
            title:
              'What renewable energy and carbon emission reduction products have Just Energy And JustGreen™ customers contributed to?',
            content: (
              <Typography>
                The JustGreen™ program helps support the development and growth
                of more than 30 green energy projects across North America. This
                has helped offset more than 667,000 metric tons of carbon
                emissions and inject more than 2.8 million mWh of renewable
                energy into the electricity grid. Click below to learn more
                about the projects Just Energy supports. View our{' '}
                <Link to="/green-energy/our-projects-impact">
                  projects and impact
                </Link>
                .
              </Typography>
            ),
          },
        ]}
      />
    ),
  },
  {
    title: 'Outages, Shortages, and Emergencies',
    content: (
      <Accordion
        items={[
          {
            title:
              'Who do I contact if there are any emergencies, leaks, or shortages with my electricity or natural gas?',
            content: (
              <Typography>
                For any issues with your distribution or supply, you need to
                contact your utility company. Utilities typically own, manage,
                and maintain power lines, wires, and pipes. If you are
                experiencing any problems or emergencies related to your
                electricity or natural gas supply, contact your local utility’s
                emergency line immediately.
              </Typography>
            ),
          },
        ]}
      />
    ),
  },
  {
    title: 'Service Areas',
    content: (
      <Accordion
        items={[
          {
            title: 'Which US states is Just Energy licensed to operate in?',
            content: (
              <Typography>
                California, Delaware, Georgia, Illinois, Indiana, Maryland,
                Massachusetts, Michigan, New Jersey, New York, Ohio,
                Pennsylvania, Texas.
              </Typography>
            ),
          },
          {
            title:
              'Which Canadian territories is Just Energy authorized to operate in?',
            content: (
              <Typography>
                Alberta, British Columbia, Manitoba, Ontario, Quebec,
                Saskatchewan.
              </Typography>
            ),
          },
        ]}
      />
    ),
  },
]

const commonCAAccordions = [
  {
    title: 'Connection and Service',
    content: (
      <Accordion
        items={[
          {
            title:
              'Which Canadian provinces is Just Energy authorized to operate in?',
            content: (
              <Typography>
                Alberta, British Columbia, Manitoba, Ontario, Quebec,
                Saskatchewan
              </Typography>
            ),
          },
          {
            title:
              'Will my natural gas and electricity bill be different if I sign up with Just Energy?',
            content: (
              <Typography>
                No, you will continue to be billed by your utility for natural
                gas and electricity. The only difference will be that you will
                see Just Energy listed as your supplier on your bill. Click
                below for an example of where Just Energy charges could appear
                on your energy bills.
              </Typography>
            ),
          },
          {
            title: 'What services does Just Energy offer?',
            content: (
              <Typography>
                Established in 1997, Just Energy (NYSE:JE, TSX:JE) is a leading
                retail consumer company specializing in electricity and natural
                gas commodities, energy efficiency solutions, and renewable
                energy options. Just Energy serves residential and commercial
                customers in Canada, the U.S., Ireland, the U.K., Germany, and
                Japan. We provide homes and businesses with a broad range of
                energy solutions that deliver greater comfort, convenience, and
                control. In some markets, our commodity offerings vary. See the
                list below to find out if serviced may be limited to either
                electricity or natural gas.
                <strong>Gas Only</strong>
                British Columbia Manitoba Saskatchewan Quebec
              </Typography>
            ),
          },
          {
            title:
              'How can I transfer my Just Energy service to a new address?',
            content: (
              <Typography>
                Service transfer depends on your location. Please contact Just
                Energy with your move request. Depending on your area, we will
                either process your move or terminate the agreement (at our sole
                discretion).
              </Typography>
            ),
          },
          {
            title: 'Can I have service at two addresses at the same time?',
            content: (
              <Typography>
                Yes, you can. However, we require a separate contract for each
                address.
              </Typography>
            ),
          },
          {
            title: 'Does transferring my service affect my contract?',
            content: (
              <Typography>
                No, the existing contract will still stay in effect. The only
                change will be the service address on record.
              </Typography>
            ),
          },
          {
            title: 'Will I have to pay a fee to transfer my service?',
            content: (
              <Typography>
                No, there is no fee required to transfer your service.
              </Typography>
            ),
          },
          {
            title: 'How can I cancel my Just Energy contract?',
            content: (
              <Typography>
                You can contact our customer service team to cancel your
                contract, but note that early termination fees may apply. Just
                Energy Customer Service: 1-877-240-3931
              </Typography>
            ),
          },
          {
            title: 'How do I change my Just Energy plan?',
            content: (
              <Typography>
                We understand that life and situations change, and with these
                adjustments, your energy needs may vary. Please call us at
                1-866-587-8674 to help you find the right energy plan for you.
              </Typography>
            ),
          },
          {
            title: 'What are some types of energy plans offered by retailers?',
            content: (
              <Typography>
                Typically, energy retailers offer price protected plans,
                allowing you to secure your commodity supply rate for a set
                period of time. Some retailers, like Just Energy, also offer
                green energy options which provide the opportunity to offset the
                negative pollution caused by your household energy consumption.
              </Typography>
            ),
          },
          {
            title:
              'What is the main benefit of Just Energy&apos;s offered plan?',
            content: (
              <Typography>
                Fixed-rate plans offer customers a set rate for their commodity
                supply over a period of time. These plans provide protection
                from market price variation as your rate is fixed and not
                affected by changes in commodity prices. Please refer to our
                plan type guide to better understand the benefits of the
                different types of plans.
              </Typography>
            ),
          },
        ]}
      />
    ),
  },
  {
    title: 'Contracts, Service Transfers and Cancelation',
    content: (
      <Accordion
        items={[
          {
            title: 'How do I change my Just Energy plan?',
            content: (
              <Typography>
                To see if changing your plan is possible, please call us at
                1-866-587-8674 or email us at cs@justenergy.com.
              </Typography>
            ),
          },
          {
            title:
              'How can I transfer my Just Energy service to a new address?',
            content: (
              <Typography>
                Please contact them to sign up and learn more about the program
                at cs@justenergy.com prior to your move. To ensure a seamless
                transition, you will also be required to contact your utility to
                request that your Just Energy program be transferred to your new
                address.
              </Typography>
            ),
          },
          {
            title:
              'Can I transfer my service and have service at two addresses at the same time?',
            content: (
              <Typography>
                You may transfer your service however it is not possible to have
                the same contract apply to two locations at the same time.
                Please contact our Customer Service representatives using any of
                the below methods to ensure a seamless transfer. Email:
                cs@justenergy.com
              </Typography>
            ),
          },
          {
            title: 'Does transferring my service affect my contract?',
            content: (
              <Typography>
                No, the existing contract will still uphold. The only change
                will be the service address on record.
              </Typography>
            ),
          },
          {
            title: 'Will I have to pay a fee to transfer my service?',
            content: (
              <Typography>
                In most cases, there is no fee to transfer your service. Refer
                to the Terms and Conditions of your contract for details.
              </Typography>
            ),
          },
          {
            title: 'How do I transfer my Just Energy Service to a new address?',
            content: (
              <Typography>
                If you are moving, please inform Just Energy by calling
                1-866-587-8674 or emailing us at cs@justenergy.com prior to your
                move. To ensure a seamless transition, you will also be required
                to contact your utility to request that your Just Energy program
                be transferred to your new address. Refer to the Terms and
                Conditions to understand your options and associated fees if you
                choose to discontinue gas supply before the end of your contract
                term.
              </Typography>
            ),
          },
          {
            title: 'How can I cancel my Just Energy contract?',
            content: (
              <Typography>
                You can only cancel your agreement with Just Energy on the
                anniversary date of your contract. To cancel your agreement,
                please call Just Energy at 1-866-587-8674. Applicable exit fees
                may apply. Refer to your Terms and Conditions for details.
              </Typography>
            ),
          },
          {
            title:
              'What is Just Energy&apos;s mailing address for exit fees or documentation?',
            content: (
              <Typography>
                If you are sending us payment by mail, please send it to: Just
                Energy, 80 Courtneypark Drive West, Units 3 &amp; 4,
                Mississauga, ON L5W 0B3.
              </Typography>
            ),
          },
          {
            title: 'What is the "Understanding Your Energy Contract" booklet?',
            content: (
              <Typography>
                This is a booklet that provides information about your options
                when it comes to selecting an energy supplier. It is important
                to read through the booklet before signing with an energy
                retailer such as Just Energy.
              </Typography>
            ),
          },
        ]}
      />
    ),
  },
  {
    title: 'Green Energy and JustGreen',
    content: (
      <Accordion
        items={[
          {
            title: 'Does Just Energy offer green energy products?',
            content: (
              <Typography>
                Yes, Just Energy does offer green energy products in all of our
                service areas.
              </Typography>
            ),
          },
          {
            title: 'What is a green energy product?',
            content: (
              <Typography>
                For electricity, a green energy product means Just Energy
                purchases renewable energy credits to offset the equivalent of a
                percentage of your household electricity consumption. Renewable
                energy credits support electricity generated from renewable
                sources such as hydro, solar, or wind. For natural gas, a green
                energy product means that Just Energy purchases carbon offsets
                to offset the equivalent of a percentage of your household
                natural gas consumption, which helps reduce your environmental
                impact.
              </Typography>
            ),
          },
          {
            title: 'Why should I buy a green energy product?',
            content: (
              <Typography>
                Buying a green energy product like JustGreen is your opportunity
                to take action on the growing global and local urgency
                associated with climate change, while simultaneously increasing
                consumer demand for sustainable and renewable green energy
                solutions.
              </Typography>
            ),
          },
          {
            title: 'What green products does Just Energy Offer?',
            content: (
              <Typography>
                Just Energy offers JustGreen™ Power commodity supply. Learn more
                about these products.
              </Typography>
            ),
          },
          {
            title: 'How Do I Sign Up For JustGreen™?',
            content: (
              <Typography>
                When you sign up for an energy plan with Just Energy, you can
                choose to offset some or all of your energy consumption for an
                added cost, when signing the contract. You can also contact us
                to sign up over the phone as an existing customer.
              </Typography>
            ),
          },
          {
            title:
              'What renewable energy and carbon emission reduction products have Just Energy And JustGreen™ customers contributed to?',
            content: (
              <Typography>
                The JustGreen program helps support the development and growth
                of more than 30 green energy projects across North America. To
                date, Just Energy has invested more than ,000 on renewable
                energy projects in Alberta alone. This has helped inject more
                than 2.8 million mWh of renewable energy into the electricity
                grid.
              </Typography>
            ),
          },
        ]}
      />
    ),
  },
  {
    title: 'Outages, Shortages, and Emergencies',
    content: (
      <Accordion
        items={[
          {
            title:
              'Who do I contact if there are any emergencies, leaks, or shortages with my electricity or natural gas?',
            content: (
              <Typography>
                For any issues with your distribution or supply, you need to
                contact your utility company. Utilities typically own, manage,
                and maintain power lines, wires, and pipes. If you are
                experiencing any problems or emergencies related to your
                electricity or natural gas supply, contact your local utility’s
                emergency line immediately.
              </Typography>
            ),
          },
        ]}
      />
    ),
  },
  {
    title: 'Service Areas',
    content: (
      <Accordion
        items={[
          {
            title: 'Which US states is Just Energy licensed to operate in?',
            content: (
              <Typography>
                California, Delaware, Georgia, Illinois, Indiana, Maryland,
                Massachusetts, Michigan, New Jersey, New York, Ohio,
                Pennsylvania, Texas.
              </Typography>
            ),
          },
          {
            title:
              'Which Canadian territories is Just Energy authorized to operate in?',
            content: (
              <Typography>
                Alberta, British Columbia, Manitoba, Ontario, Quebec,
                Saskatchewan.
              </Typography>
            ),
          },
        ]}
      />
    ),
  },
]

const JEAdvisor = [
  {
    title: 'Just Energy Advisor App',
    content: (
      <Accordion
        items={[
          {
            title: 'What can I do from the Just Energy Advisor App?',
            content: (
              <Typography>
                You can check your usage and bill projections, rebate offers,
                set up auto pay, or report an outage with your utility provider
                or to Just Energy. We also offer tips to help you improve energy
                efficiency, schedule maintenance, and schedule maintenance
                reminders on home and appliance equipment. Texas customers are
                further able to use the mobile app to access an overview of your
                current services, including plan and term details, and
                cancellation fees.
              </Typography>
            ),
          },
        ]}
      />
    ),
  },
]

const YourOnlineAccount = [
  {
    title: 'Your Online Account',
    content: (
      <Accordion
        items={[
          {
            title: 'How do I set up my Just Energy account online?',
            content: (
              <Typography>
                New and existing Just Energy customers need to first{' '}
                <a href="https://my.justenergy.com/Profile/Register">
                  create an account
                </a>{' '}
                on our My Energy World portal. Simply enter your Just Energy
                account number and postal code to get started.
              </Typography>
            ),
          },
          {
            title: 'How do I login into My Energy World?',
            content: (
              <Typography>
                You can securely access{' '}
                <a href="https://my.justenergy.com/">your account</a> by
                entering your email address and the password you created during
                sign up.
              </Typography>
            ),
          },
          {
            title:
              'How do I retrieve or reset my password if I can’t get into my Account?',
            content: (
              <Typography>
                To retrieve or set up a new password, select the &quote;
                <a href="https://my.justenergy.com/Profile/ForgotPassword">
                  Forgot Password?
                </a>
                &quote; option and enter the email address associated with your
                account.
              </Typography>
            ),
          },
          {
            title: 'What can I do from My Energy World account?',
            content: (
              <Typography>
                Your online account allows to you to:{' '}
                <ul>
                  <li>View your account information</li>
                  <li>Check billing information</li>
                  <li>Pay your account online</li>
                  <li>Set up email billing alerts</li>
                  <li>See what green products we have available</li>
                  <li>View special offers and news from Just Energy</li>
                  <li>Read tips for energy efficiency</li>
                  <li>Consult our frequently asked questions and answers</li>
                </ul>
              </Typography>
            ),
          },
          {
            title:
              'How do I change the email address associated with my account?',
            content: (
              <Typography>
                To change your email address, navigate to your “Profile” to
                replace it with your new or preferred email.
              </Typography>
            ),
          },
        ]}
      />
    ),
  },
]

export const geoTabData = {
  California: [...commonUSAccordions],
  Delaware: [...commonUSAccordions],
  Illinois: [
    ...commonUSAccordions,
    {
      title: 'Regional Regulatory Information and Disclosures',
      content: (
        <Accordion
          items={[
            {
              title: 'What Is The Illinois Public Service Commission (PSC)?',
              content: (
                <Typography>
                  The ICC is a major regulatory body for all consumer,
                  processes, registries and transport in Illinois, including
                  utilities such natural gas and electricity.{' '}
                  <a
                    href="https://www.icc.illinois.gov/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Learn more
                  </a>
                  .
                </Typography>
              ),
            },
          ]}
        />
      ),
    },
  ],
  Indiana: [
    ...commonUSAccordions,
    {
      title: 'Regional Regulatory Information and Disclosures',
      content: (
        <Accordion
          items={[
            {
              title: 'What is the Office of Utility Consumer Counselor (OUCC)?',
              content: (
                <Typography>
                  OUCC is the regulatory agency for services including water,
                  natural gas and electricity. Learn more.
                </Typography>
              ),
            },
          ]}
        />
      ),
    },
  ],
  Maryland: [
    ...commonUSAccordions,
    {
      title: 'Regional Regulatory Information and Disclosures',
      content: (
        <Accordion
          items={[
            {
              title: 'What is the Maryland Public Service Commission (PSC)?',
              content: (
                <Typography>
                  PSC is a major regulatory body for all services including
                  telephone, cable, water, rail, vehicle, natural gas and
                  electricity.{' '}
                  <a
                    href="https://www.psc.state.md.us/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Learn more
                  </a>
                  .
                </Typography>
              ),
            },
          ]}
        />
      ),
    },
  ],
  Massachusetts: [
    ...commonUSAccordions,
    {
      title: 'Regional Regulatory Information and Disclosures',
      content: (
        <Accordion
          items={[
            {
              title: 'What is the Department of Public Utilities??',
              content: (
                <Typography>
                  The Department of Public Utilities is responsible for
                  oversight of investor-owned electric power, natural gas and
                  water utilities in the Commonwealth; developing alternatives
                  to traditional regulation; monitoring service quality;
                  regulating safety in the transportation and gas pipeline
                  areas; and for the siting of energy facilities. The mission of
                  the Department is to ensure that utility consumers are
                  provided with the most reliable service at the lowest possible
                  cost; to protect the public safety from transportation and gas
                  pipeline related accidents; to oversee the energy facilities
                  siting process; and to ensure that residential
                  ratepayers&apos; rights are protected.{' '}
                  <a
                    href="https://www.mass.gov/orgs/department-of-public-utilities"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Department of Public Utilities
                  </a>
                  .
                </Typography>
              ),
            },
            {
              title:
                'What is the Just Energy Disclosure Label for Massachusetts Customers?',
              content: (
                <Typography>
                  Click below to view or download the Just Energy Disclosure
                  Label for Massachusetts customers.{' '}
                  <Link to="/">Disclosure Label</Link>
                  <br />
                  <Link to="/">Grafica de Divulgacion</Link>
                </Typography>
              ),
            },
          ]}
        />
      ),
    },
  ],
  Michigan: [
    ...commonUSAccordions,
    {
      title: 'Regional Regulatory Information and Disclosures',
      content: (
        <Accordion
          items={[
            {
              title: 'What is the Michigan Public Service Commission (MPSC)?',
              content: (
                <Typography>
                  The MPSC is a major regulatory body for all processes,
                  registries and transport in Michigan, including natural gas
                  and electricity. Learn more.
                </Typography>
              ),
            },
            {
              title: 'What is the Department of Public Utilities?',
              content: (
                <Typography>
                  The MPSC is a major regulatory body for all processes,
                  registries and transport in Michigan, including natural gas
                  and electricity.{' '}
                  <a
                    href="https://www.michigan.gov/mpsc"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Learn more
                  </a>
                  .
                </Typography>
              ),
            },
          ]}
        />
      ),
    },
  ],
  New_Jersey: [
    ...commonUSAccordions,
    {
      title: 'Regional Regulatory Information and Disclosures',
      content: (
        <Accordion
          items={[
            {
              title: 'What is the New Jersey Board Of Public Utilities (BPU)?',
              content: (
                <Typography>
                  BPU is a major regulatory body for all services including
                  telephone, cable, water, natural gas and electricity.{' '}
                  <Link to="https://www.nj.gov/bpu/about/index.html">
                    Learn more
                  </Link>
                  .
                </Typography>
              ),
            },
          ]}
        />
      ),
    },
  ],
  New_York: [
    ...commonUSAccordions,
    {
      title: 'Regional Regulatory Information and Disclosures',
      content: (
        <Accordion
          items={[
            {
              title:
                'What is the New York State Department of Public Service (DPS)?',
              content: (
                <Typography>
                  The New York State Public Service Commission (DPS) is a major
                  regulatory body for all services including telephone, water,
                  natural gas and electricity.{' '}
                  <a
                    href="http://www.dps.ny.gov/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Learn more
                  </a>
                  .
                </Typography>
              ),
            },
            {
              title: 'What is NYISO?',
              content: (
                <Typography>
                  The New York Independent System Operator (NYISO) is a
                  not-for-profit corporation responsible for operating the
                  state’s bulk electricity grid, administering New York’s
                  competitive wholesale electricity markets, conducting
                  comprehensive long-term planning for the state’s electric
                  power system, and advancing the technological infrastructure
                  of the electric system serving the Empire State. Please visit
                  its website for more information:{' '}
                  <a
                    href="https://www.nyiso.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    www.nyiso.com
                  </a>
                </Typography>
              ),
            },
            {
              title: 'What is FERC?',
              content: (
                <Typography>
                  The Federal Energy Regulatory Commission (FERC) is the United
                  States federal agency with jurisdiction over interstate
                  electricity sales, wholesale electric rates, hydroelectric
                  licensing, natural gas pricing and oil pipeline rates.
                </Typography>
              ),
            },
            {
              title: 'What is a "capacity cost"?',
              content: (
                <Typography>
                  Capacity cost is paid to power generators to ensure that
                  there’s enough power produced during &quot;peak&quot; hours.
                  Those are the hours in the hottest days of the year. As
                  electricity cannot be easily stored, generators need to be
                  incented to build more power plants to meet demand, which
                  drives up the capacity costs in areas where generation is
                  scarce.
                </Typography>
              ),
            },
          ]}
        />
      ),
    },
  ],
  Ohio: [
    ...commonUSAccordions,
    {
      title: 'Regional Regulatory Information and Disclosures',
      content: (
        <Accordion
          items={[
            {
              title: 'What is the Public Utility Commission Of Ohio (PUCO)?',
              content: (
                <Typography>
                  The PUCO is the regulatory agency for all services including
                  telephone, water, natural gas and electricity.{' '}
                  <a
                    href="https://www.puco.ohio.gov/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Learn more
                  </a>
                  .
                </Typography>
              ),
            },
          ]}
        />
      ),
    },
  ],
  Pennsylvania: [
    ...commonUSAccordions,
    {
      title: 'Regional Regulatory Information and Disclosures',
      content: (
        <Accordion
          items={[
            {
              title:
                'What is the Pennsylvania Public Utility Commission (PUC)?',
              content: (
                <Typography>
                  Pennsylvania PUC is a major regulatory body for all services
                  including telephone, rail, natural gas and electricity.{' '}
                  <a
                    href="http://www.puc.state.pa.us/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Learn more
                  </a>
                  .
                </Typography>
              ),
            },
          ]}
        />
      ),
    },
  ],
  Texas: [
    ...JEAdvisor,
    ...commonUSAccordions,
    {
      title: 'Regional Regulatory Information and Disclosures',
      content: (
        <Accordion
          items={[
            {
              title: 'What is the Public Utility Commission of Texas (PUC)?',
              content: (
                <Typography>
                  The PUC is the major regulatory body for all electric services
                  in Texas.
                </Typography>
              ),
            },
            {
              title: 'What is a PUC Assessment?',
              content: (
                <Typography>
                  A fee assessed to recover the costs for administering the
                  Public Utility Regulatory Act, which primarily is intended to
                  fund the PUC.
                </Typography>
              ),
            },
            {
              title:
                'What is miscellaneous gross receipts tax reimbursement (GRT)?',
              content: (
                <Typography>
                  A fee assessed to recover the miscellaneous gross receipts tax
                  imposed on retail electric providers operating in an
                  incorporated city or town having a population of more than
                  1,000.
                </Typography>
              ),
            },
            {
              title: 'What are your rights as a customer (YRAC)?',
              content: (
                <Typography>
                  To ensure that you are aware of Your Rights as a Customer, the
                  Public Utility Commission of Texas requires that with any
                  electricity contract you sign up for, you receive a copy of
                  Your Rights as a Customer.
                </Typography>
              ),
            },
            {
              title:
                'What is an EECRF (energy efficiency cost recovery factor)?',
              content: (
                <Typography>
                  The Texas Energy Efficiency Cost Recovery Factor (EECRF) Rider
                  is in effect as a result of PUCT regulations designed to
                  establish a mechanism under which electric utilities may
                  recover costs associated with providing energy efficiency
                  programs.
                </Typography>
              ),
            },
          ]}
        />
      ),
    },
  ],
  Alberta: [
    ...YourOnlineAccount,
    ...JEAdvisor,
    ...commonCAAccordions,
    {
      title: 'Regional Regulatory Information and Disclosures',
      content: (
        <Accordion
          items={[
            {
              title: 'What does Just Energy offer in Alberta?',
              content: (
                <Typography>
                  Just Energy currently offers a Price Protect Electricity and
                  Natural Gas programs along with a Predict-a-Bill Natural Gas
                  program.{' '}
                  <Link to="residential-plans/#/enrollment/US/TX/SVC/residential-plans">
                    Learn more
                  </Link>{' '}
                  about Just Energy&apos;s plans available in Alberta.
                </Typography>
              ),
            },
          ]}
        />
      ),
    },
  ],
  British_Columbia: [
    ...commonCAAccordions,
    {
      title: 'Regional Regulatory Information and Disclosures',
      content: (
        <Accordion
          items={[
            {
              title: 'How much will I be charged If I end my contract early?',
              content: (
                <Typography>
                  You have 10 days after receiving your FortisBC confirmation
                  letter to cancel your contract with Just Energy without
                  penalty. Refer to the Terms and Conditions of your contract
                  for complete details.
                </Typography>
              ),
            },
            {
              title:
                'What is the British Columbia Utilities Commission (BCUC)?',
              content: (
                <Typography>
                  The BCUC is the regulatory body that governs natural gas and
                  electricity in British Columbia.
                </Typography>
              ),
            },
            {
              title:
                'What is the Customer Choice Standard Information booklet?',
              content: (
                <Typography>
                  This is a booklet that helps you understand and read about
                  your energy supplier choice options. It is required that you
                  read through before signing with a retailer such as Just
                  Energy.
                </Typography>
              ),
            },
          ]}
        />
      ),
    },
  ],
  Ontario: [
    ...commonCAAccordions,
    {
      title: 'Regional Regulatory Information and Disclosures',
      content: (
        <Accordion
          items={[
            {
              title:
                'Is Just Energy licensed to sell natural gas and electricity in Ontario?',
              content: (
                <Typography>
                  Just Energy is licensed by the Ontario Energy Board (OEB) to
                  market natural gas under Gas Marketer License GM-2010-0152 and
                  electricity under Electricity Retailer License ER–2010-0153.
                </Typography>
              ),
            },
            {
              title: 'What is the Global Adjustment?',
              content: (
                <Typography>
                  The Global Adjustment accounts for differences between the
                  market price and rates paid to regulated generators, and the
                  costs of generation contracts held with the Ontario Power
                  Authority. This is a credit or charge determined by the
                  Ontario government to be assigned to all Ontario electricity
                  consumers. This adjustment was implemented January 1, 2005,
                  and the rate is adjusted monthly. The amount calculated and
                  applied by utilities to non-RPP consumer invoices varies based
                  on the meter read/bill cycle for each customer. The Global
                  Adjustment is set to reflect the difference between the market
                  price and: The regulated rate paid to Ontario Power
                  Generation&apos;s baseload generating stations Payments made
                  to suppliers that were awarded contracts through the
                  government&apos;s RFP process Contracted rates paid to
                  Non-Utility Generators. The Global Adjustment impacts all
                  customers, regardless of if they are with the utility or a
                  retailer. The only difference is that customers with a
                  retailer will see the Global Adjustment broken out as a
                  separate line item on their bill. The Global Adjustment was
                  formerly known as the Provincial Benefit.
                </Typography>
              ),
            },
            {
              title: 'What is Final Variance Settlement?',
              content: (
                <Typography>
                  For customers on the RPP, the difference between the amount
                  consumers have paid for electricity and the amount that has
                  been paid to generators is tracked in an account. Consumers
                  who move out of province or leave the RPP for a retailer or
                  spot market pricing will need to settle their share of the
                  balance of that account. This may result in a credit or a
                  charge, depending on price of electricity paid to generators.
                  For customers who remain on the RPP, the amount of the
                  settlement will be rolled into the new pricing set every six
                  months. Customers who protect their price with Just Energy
                  will only pay a one-time settlement.
                </Typography>
              ),
            },
            {
              title: 'What is time-to-use (Tou) pricing?',
              content: (
                <Typography>
                  The provincial government has imposed a new complicated
                  electricity plan known as time-of-use pricing where the rate
                  you are charged depends on when you use your electricity and
                  the rates are different for On-Peak usage hours, Mid-Peak
                  usage hours and Off-Peak usage hours. Mid-Peak and On-Peak
                  hours flip in the winter and summer due to demand factors and
                  rates can change every 6 months.{' '}
                  <Link to="/residential-plans/#/enrollment/US/TX/SVC/residential-plans">
                    Ontario Residential
                  </Link>
                </Typography>
              ),
            },
            {
              title: 'What is the Ontario system-wide electricity supply mix?',
              content: (
                <Typography>
                  Click below to view the Electricity Facts Label chart that
                  shows: (i) Just Energy&apos;s electricity supply mix with 1 to
                  5 units of JustGreen Power; and (ii) Ontario&apos;s
                  system-wide electricity supply mix (2015 data, as determined
                  by the Ministry of Energy).{' '}
                  <a href="https://justenergy.com/pdf/on_svc_mvc_ele_supplymix_eng_v1_jun_28_19_v3.pdf">
                    Electricity Facts Label
                  </a>
                </Typography>
              ),
            },
            {
              title: 'What is the Ontario Energy Board (OEB)?',
              content: (
                <Typography>
                  The Ontario Energy Board is the regulatory body that governs
                  natural gas and electricity in Ontario. Click below to{' '}
                  <a href="https://www.oeb.ca/">learn more</a>.
                </Typography>
              ),
            },
            {
              title: 'What is a price comparison form?',
              content: (
                <Typography>
                  A Price Comparison Form lists your current utility&apos;s rate
                  and charges for your given commodity, as well as the proposed
                  rate and charges by the energy retailer. Before entering into
                  an agreement with a retailer, a customer must sign (or for
                  online enrolments acknowledge) the price comparison form.
                </Typography>
              ),
            },
            {
              title:
                'Why does Just Energy require a price comparison form to be signed as part of my energy contract?',
              content: (
                <Typography>
                  Under new legal and regulatory requirements that came into
                  effect January 1, 2011, all new and renewal residential and
                  small to mid-sized commercial natural gas and electricity
                  contracts are required to be accompanied by a board-approved
                  Price Comparison Form.
                </Typography>
              ),
            },
            {
              title:
                'Why does Just Energy require a disclosure statement to be signed as part of my energy contract?',
              content: (
                <Typography>
                  Under new legal and regulatory requirements that came into
                  effect January 1, 2011, all new and renewal residential and
                  small to mid-sized commercial natural gas and electricity
                  contracts are required to be accompanied by a board-approved
                  Disclosure Statement.
                </Typography>
              ),
            },
          ]}
        />
      ),
    },
  ],
  Quebec: [...commonCAAccordions],
  Saskatchewan: [
    ...commonCAAccordions,
    {
      title: 'Regional Regulatory Information and Disclosures',
      content: (
        <Accordion
          items={[
            {
              title:
                'Why does Just Energy require a disclosure statement to be signed as part of my energy contract?',
              content: (
                <Typography>
                  A customer must sign the Disclosure Statement (or the
                  &quote;Commodity Sale of Natural Gas Disclosure Statement and
                  Appointment of Agent&quote;) in order to appoint a gas
                  retailer as the customer&apos;s agent for certain purposes.
                  This document also permits SaskEnergy to exchange customer
                  account information with the gas retailer and highlights the
                  benefits and risks associated with the commodity sale of
                  natural gas.
                </Typography>
              ),
            },
            {
              title: 'What is a PUC Assessment?',
              content: (
                <Typography>
                  A fee assessed to recover the costs for administering the
                  Public Utility Regulatory Act, which primarily is intended to
                  fund the PUC.
                </Typography>
              ),
            },
            {
              title: 'What is disclosure statement?',
              content: (
                <Typography>
                  A customer must sign the Disclosure Statement (or the
                  &quote;Commodity Sale of Natural Gas Disclosure Statement and
                  Appointment of Agent&quote;) in order to appoint a gas
                  retailer as the customer&apos;s agent for certain purposes.
                  This document also permits SaskEnergy to exchange customer
                  account information with the gas retailer and highlights the
                  benefits and risks associated with the commodity sale of
                  natural gas.
                </Typography>
              ),
            },
          ]}
        />
      ),
    },
  ],
  Manitoba: [...commonCAAccordions],
}
